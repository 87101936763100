@import 'app/styles/breakpoints';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 3%;

  .description {
    text-align: center;
  }

  &:after {
    @media screen and (max-width: map-get($breakpoints, md)) {
      left: unset;
      bottom: 0;
      height: 1px;
      width: 100%;
    }
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding: 20px 0;
  }
}

.btnWrapp {
}

.tgBtn {
  height: auto!important;
}

.chipsBlock {
  display: flex;
  gap: 10px;
  align-content: flex-start;
  flex-wrap: wrap;
}
