@import 'app/styles/breakpoints';


.formContainer {
  position: sticky;
  top: 2.5vh;
  width: 100%;
}

.fillterContainer {
  max-width: 100%;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: none;
  }
}

.mobileFiltersContainer {
  display: none;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: grid;
    grid-template-rows: auto auto;
    gap: 10px;
    justify-items: stretch;
  }
}

.btnFilters {
  display: grid;
  border-radius: 5px;
  grid-template-columns: 0.1fr 0.9fr;
  padding: 5px 10px;
  height: unset;
  gap: 10px;
  justify-items: flex-start;
  max-width: 354px;
  align-items: center;
}