@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.form {
  position: relative;
  display: flex;
  gap: 40px;
  flex-direction: column;
  padding: 40px;

}

.input {
  display: flex;
  flex-direction: column;
  gap: 5px
}

.itemWrapper {
  max-width: 350px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include tablet {
    width: 100%;
  }
}

.error {
  color: red;
}

.fioWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.btnWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.textInBtn {
  color: white;
  padding: 10px 30px;
  font-weight: 600;
}

.btnLogin {
  height: unset;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioBtnText {
  font-weight: 400;
}

.btnText {
  color: var(--primary-btn-color);
}

.btn {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.textWrapper {
  display: flex;
  gap: 5px;
}
