@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  gap: 12px;
}

.fioWrap {
  display: grid;
  gap: 4px;
  grid-template-columns: auto auto auto;
}

.row {
  max-width: 75%;
  display: grid;
  grid-template-rows: 0.2fr 0.8fr;
  gap: 4px;

  @include tablet {
    max-width: 100%;
  }
}

.sexGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.uploadWrapp {
  display: grid;
  gap: 8px;
}

.imagesWrapp {
  display: grid;
  grid-template-columns: 0.2fr auto;
  align-items: start;
  gap: 8px;
}
