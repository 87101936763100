@import 'app/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5% 0;

  .description {
    text-align: center;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.block {
  &:before {
    position: absolute;
    content: '';
    top: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    color: #e8e8e8;
  }
}

.onlineCons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 3% 0;

  @media screen and (max-width: map-get($breakpoints, md)) {
    padding: 20px 0;
  }
}

.viewMore {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;

  button {
    padding: 20px;
  }
}

.btnWrapper {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    //width: 100%;
  }
}

.btn {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}
