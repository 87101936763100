@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: var(--white-color);
  padding: 30px 15px;
  max-height: 90vh;
  width: 50%;
  overflow: auto;

  @include tablet {
    width: 90%;
  }
}

.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 30px auto;
}

.btnFirstWrap {
  flex-direction: row-reverse;
}