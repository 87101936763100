@import "app/styles/breakpoints";

.achievementsCard {
  padding: 2rem 3rem;
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  justify-items: center;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: 0.2fr 0.8fr;
    grid-template-columns: auto;
  }
}