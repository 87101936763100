@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.root {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.now {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.fixData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.titleNow {
  z-index: 1;
}

.sessionNow {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 68px 0;
}

.cardNow {
  width: 80%;
  padding: 20px;
  position: relative;
  display: grid;
  background-color: var(--white-color);
  z-index: 1;
  border-radius: 20px;
  grid-gap: 20px;
  grid-template-columns: 180px 1fr 1fr;

  @include tablet {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nameBlock {
  display: flex;
  flex-direction: column;
}

.weightTitle {
  font-size: 36px;
  font-weight: 400;
}

.infoBlock {
  display: flex;
  gap: 40px;
}

.textWrap {
  display: flex;
  flex-direction: column;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigBtn {
  display: flex;
  align-items: center;
  padding: 117.5px 160px;
  border-radius: 127px;

  @include tablet {
    padding: 25px 40px;
    border-radius: 12px;
  }
}


.videoWrap {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateY(-50%);

  @include mobile {
    display: none;
  }
}

.videoItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableCont {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.columnsTitle {
  position: relative;
  display: grid;
  grid-template-columns: 165px 165px 150px 150px 70px 135px auto;
  padding: 10px 0;
  grid-gap: 20px;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #E8E8E8;
  }
}

.item {
  position: relative;
  display: grid;
  grid-template-columns: 165px 165px 150px 150px 70px 135px auto;
  padding: 20px 0;
  grid-gap: 20px;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color:#E8E8E8;
  }
}

.subItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.longText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnText {
  display: flex;
  min-width: 165px;
  align-items: center;
  background: transparent;
  gap: 5px;
  border: none;
  border-radius: 12px;
  .calendar {
    width: 24px;
    height: 24px;
    mask-image: url("../../../1_shared/assets/pictures/stack/icons/calendar.svg");
    background-color: var(--gray-color);
    background-repeat: no-repeat;
  }

  &:hover {
    .btnTextDescr {
      color: var(--primary-btn-color);
    }
    .calendar {
      background-color: var(--primary-btn-color);
    }
  }
}

.btnDanger {
  background: #FFE3E1 !important;
  color: #CC3B33 !important;
}

