@import "../../../app/styles/global/mixins";
@import "../../../app/styles/global/variables";

.order-details {
  padding: 50px 0;

  @include mobile {
    padding-bottom: 30px;
  }
}

.order-details__title {
  margin-top: 0;
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 10px;
  }
}

.order-details__list {
  @include list();

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 52px;

  @include mobile {
    display: block;
    margin-bottom: 0;
  }
}

.order-details__item {
  margin-right: 40px;
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
  }

  p {
    color: $color-black;
    margin-top: 0;
    margin-bottom: 10px;
  }

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 0;

    p {
      min-width: 188px;
      margin-bottom: 0;
    }
  }
}

.order-details__label {
  display: block;
  color: $color-dot-grey;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 5px;

  @include mobile {
    width: 100%;
    font-size: 8px;
    line-height: 1.25;
  }
}

.order-details__control {
  display: inline-block;
  position: relative;
  background-color: transparent;
  color: $color-dot-grey;
  font-size: 12px;
  line-height: 1.5;
  padding-left: 27px;
  cursor: pointer;

  &--calendar {
    &::before {
      @include pseudo();

      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;

      @include icon-bg();

      background-image: url("../../../1_shared/assets/pictures/stack/stack.svg#calendar");
    }

    &:hover {
      &::before {
        background-image: url("../../../1_shared/assets/pictures/stack/stack.svg#calendar-hover");
      }
    }
  }

  &--checkbox {
    input[type="checkbox"] {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      white-space: nowrap;
      clip-path: inset(100%);
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    span::before {
      @include pseudo();

      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border: 2px solid $color-dot-grey;
      border-radius: 50%;
    }

    span::after {
      @include pseudo();

      display: none;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 13px;
      height: 10px;

      @include icon-bg();

      background-image: url("../../../1_shared/assets/pictures/stack/stack.svg#check");
    }

    input[type="checkbox"]:checked + span {
      color: $color-dot-dark;

      &::before {
        border-color: $color-dot-blue;
      }

      &::after {
        display: block;
      }
    }

    &:hover {
      span::before {
        border-color: $color-dot-blue;
      }
    }
  }

  &:hover {
    color: $color-dot-dark;
  }
}

.order-details__connect {
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

  @include mobile {
    p {
      margin-bottom: 10px;
    }
  }
}

.order-details__connect-btn {
  @include mobile {
    width: 100%;
    padding: 13px;
  }
}
