.root {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.2fr 0.8fr;
  width: 380px;
}

.darkText {
  color: var(--white-color);
}
