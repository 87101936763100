@import 'app/styles/breakpoints';

.content {
  width: 100%;
  display: flex;
  padding: 2.5%;
  justify-content: center;
}

.bg_lightBlue {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--light-blue) !important;
}

.contentContainer {
  width: 100%;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 90%;
  }
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 95%;
  }
}
.contentUZ {
  width: 90%;
  display: flex;
  justify-content: center;
}
.filtersUZ {
  width: 35vh;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}
