@import 'app/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--gray-color);
  @media screen and (max-width: map-get($breakpoints, md)) {
    padding: unset;
    border: unset;
    border-radius: unset;
    }
}

.icon {
  @media screen and (max-width: map-get($breakpoints, md)) {
    width: 18px;
    height: 18px;


    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.workWithContainer {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-content: space-between;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr;


  }
}

.dateContainer {
  display: grid;
  grid-template-columns: repeat(4, 15%);
  gap: 20px;
  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-rows: repeat(4, 15%);
    grid-template-columns: 1fr;
  }
}

.workWith {
  //width: 100% !important;
}

.error {
  color: red;
}
