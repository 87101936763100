.root {
  position: absolute;
  border: 1px solid #FFFDE9;
  display: flex;
  padding: 5px;
  flex-direction: column;
  gap: 5px;
  background-color: #FFFDE9;
  width: 100%;

}

.rootDeleteble {
  z-index: 11;
  &:hover {
    border: 1px solid orangered;
    border-radius: 5px;
    z-index: 12;
    -webkit-box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
    box-shadow: 8px 12px 8px -2px rgba(34, 60, 80, 0.15);
    cursor: pointer;

    &:after {
      position: absolute;
      top: 5px;
      right: 10px;
      content: '';
      width: 1px;
      height: 15px;
      transform: rotate(45deg);
      background-color: orangered;
    }

    &:before {
      position: absolute;
      top: 5px;
      right: 10px;
      content: '';
      width: 1px;
      height: 15px;
      transform: rotate(-45deg);
      background-color: orangered;
    }
  }
}

.rootPrev {
  background-color: #F5F5F5 !important;
  border: 1px solid #F5F5F5;
}