@import 'app/styles/breakpoints';

.root {
  display: grid;
  grid-template-rows: 1fr 0.2fr;
}

.nameAndSpec {
  display: grid;
  gap: 25px;
  grid-template-rows: 0.5fr 0.5fr;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;
  }
}

.nameBlock {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    gap: 0;
  }
}

.secondName,
.firstName {
  font-size: 64px;
  vertical-align: bottom;

  @media screen and (max-width: map-get($breakpoints, md)) {
    font-size: 40px;
  }
  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 36px;
  }
}

.secondName {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    margin-bottom: 4%;
  }
}

.spec {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.2fr 0.8fr;
}

.infoSpec {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  gap: 25px;
  align-items: start;
}

.defaultInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.infoSpecBloc {
  display: grid;
  gap: 5px;
  grid-template-rows: 0.5fr 0.5fr;
}

.titleInfo {
  font-weight: bold;
}

.buttonSecondary {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    font-size: 12px;
  }
}

.dateBlock {
  display: grid;
  grid-template-rows: auto auto;
  gap: 5px;
}

.superVision {
  background: #7f7fff;
  border: 1px solid #7f7fff !important;
  color: white !important;

  &:hover {
    color: white;
  }
}

.textChips {
  font-size: 12px;
}

.textChipsSuper {
  color: white !important;
}

.specFirst,
.specSecond {
  width: 75%;
  display: grid;
  gap: 20px;
}

.specFirst {
  grid-template-columns: 2fr 3fr;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 10px;
    margin-bottom: 20px;

    .specLineItem:nth-child(3) {
      grid-column: span 2;
    }
  }
}

.specSecond {
  grid-template-columns: 2fr 3fr;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-template-columns: 1fr;
  }
}

.specLineItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.specialistPersonInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
  }
}
