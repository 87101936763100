@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.about {
  padding: 50px 0;

  @include tablet {
    padding-bottom: 30px;
  }
}

.about__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.about__inner {
  display: flex;
  padding-bottom: 50px;

  @include tablet {
    display: block;
    padding-bottom: 0;
  }
}

.about__half {
  width: 50%;

  &--flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include tablet {
    width: 100%;

    &--flex {
      max-width: 400px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &--list-wrap {
      overflow-x: auto;
      padding-bottom: 20px;
    }
  }

  @include mobile {
    &--flex {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.about__title {
  margin-top: 0;
  margin-bottom: 17px;

  @include mobile {
    margin-bottom: 10px;
  }
}

.about__subtitle {
  max-width: 255px;
  color: $color-black;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @include tablet {
    max-width: 400px;
  }
}

.about__project {
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    margin-top: 20px;
    margin-bottom: 21px;
  }
}

.about__project-title {
  max-width: 214px;
  font-family: $font-secondary;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 12px;

  @include tablet {
    margin: 0;
  }
}

.about__project-image {
  width: 101px;
}

.about__list {
  @include list();

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;

  @include tablet {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-auto-flow: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.about__item {
  padding: 20px;
  background-color: var(--light-chip);
  border-radius: 20px;

  svg {
    path {
      stroke: var(--primary-btn-color);
    }
    rect {
      stroke: var(--primary-btn-color);
    }
  }


  p {
    margin: 0;
  }


  @include tablet {
    width: 250px;
  }

  @include mobile {
    width: 220px;
    padding-bottom: 12px;
  }
}
