@import 'app/styles/breakpoints';

.root {
  margin-left: 12px;
  display: grid;
  justify-content: end;
  height: 35%;
  grid-template-rows: auto auto;

  @media screen and (max-width: map-get($breakpoints, md)) {
    justify-content: center;
    grid-template-rows: auto;
    margin-left: 0;
    height: 100%;
    gap: 14px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    justify-content: stretch;
  }
}

.btnNext,
.infoBtn {
  @media screen and (max-width: map-get($breakpoints, md)) {
    height: 40px;
    font-size: 12px;
  }
}

.btnSubmit {
  @media screen and (max-width: map-get($breakpoints, md)) {
    height: 40px;
  }
}

.darkBtn {
  background: transparent;
  border: 1px solid var(--primary-btn-color);
  color: var(--primary-btn-color);
}

.btnNext {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}

.infoBtn {
  @media screen and (max-width: map-get($breakpoints, sm)) {
    width: 100%;
  }
}
