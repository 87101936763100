.select {
  box-shadow: none;

  :global(.ant-select-selector) {
    box-shadow: none !important;
  }
}

.filledUp {
  :global(.ant-select-selector),
  input {
    background-color: var(--mint-color) !important;
    box-shadow: none !important;
  }
  :global(.ant-select-clear) {
    background-color: transparent;
  }
}
