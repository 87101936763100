@import 'app/styles/global/variables';
@import 'app/styles/global/mixins';
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100vh;

  // @include tablet {
  //   gap: 10px;
  // }
  // @include mobile {
  //   gap: 10px;
  // }
}
