.root {
  display: flex;
  background-color: transparent;
  border: solid 1px;
  border-radius: 25px;
  border-color: var(--primary-btn-color);
}

.content {
  display: flex;
  padding: 10px 15px;
}

.activeContent {
  padding: 0 0 0 15px;
  align-items: center;
}

.active {
  background-color: var(--primary-btn-color);
  color: white;
}

.imgButton {
  padding: 6px;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: #150058;
    transition: all 0.2ms;
  }
}
