.label {
  display: flex;
  max-width: min-content;
  align-items: center;
  gap: 0.4rem;

  &Right {
    flex-direction: row-reverse;
  }
}
