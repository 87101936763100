@import "app/styles/breakpoints";

.root {
  margin-top: -60px;
  position: relative;
  display: grid;
  padding: 20px 0;
  gap: 21px;
  grid-template-columns: 300px auto 0.4fr;
  align-items: center;

  &:first-child {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      height: 1px;
      background-color: #E8E8E8;
      color: #E8E8E8;
    }

    @media screen and (max-width: map-get($breakpoints, sm)) {
      margin-top: -30px;
      display: flex;
      flex-direction: column;
    }
  }

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #E8E8E8;
    color: #E8E8E8;
  }
}