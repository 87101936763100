@import 'app/styles/breakpoints';

.root {
  position: relative;
  width: 100%;

  &:first-child {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      color: #e8e8e8;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      color: #e8e8e8;
    }
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    color: #e8e8e8;
  }
}
.toggleButton {
  display: flex;
  align-self: center;
}

.showDescription {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: map-get($breakpoints, md)) {
    align-items: center;
  }
}

.toggleIcon {
  transition: transform 0.3s;
}

.iconRotated {
  transform: rotate(180deg);
}


.aboutSpec {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rootDark {
  background-color: var(--card-dark);
  border-radius: 20px;
  color: white !important;
  padding: 20px 50px;

  &:first-child {
    &:after {
      width: 0 !important;
    }
  }

  &:before {
    width: 0 !important;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: none;
  }
}

.wrapperForm {
  display: grid;
  padding: 20px 0;
  gap: 21px;
  grid-template-areas:
    'IMAGE NAME INFO BUTTONS'
    'IMAGE SPEC KIND BUTTONS'
    'IMAGE SLOTS SLOTS BUTTONS';
  grid-template-columns: 18% 1fr auto 180px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: flex;
    flex-direction: column;
    grid-template-columns: auto auto;
    grid-template-areas:
      'IMAGE NAME'
      'IMAGE INFO'
      'SPEC SPEC'
      'SLOTS SLOTS'
      'KIND KIND'
      'BUTTONS BUTTONS';
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nameBlock {
  grid-area: NAME;
  display: grid;
  gap: 5px;
  grid-template-rows: 0.2fr 0.8fr;
}

.nameBlockDark {
  color: var(--white-color) !important;
}

.infoSpecBloc {
  justify-items: start;
  grid-area: KIND;
  white-space: nowrap;
  display: grid;
  gap: 5px;
  grid-template-rows: 0.5fr 0.5fr;
}

.wrapperImage {
  grid-area: IMAGE;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: map-get($breakpoints, md)) {
    min-height: 200px;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    min-height: 127px;
  }
}

.wrapperBtns {
  grid-area: BUTTONS;
}

.wrapperInfo {
  grid-area: INFO;
}

.wrapperSpec {
  grid-area: SPEC;
}

.wrapperSlot {
  grid-area: SLOTS;
}

.darkChips {
  background: transparent;
  color: var(--white-color) !important;
}
