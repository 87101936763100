@import 'app/styles/breakpoints';

.row {
  display: grid;
  align-items: center;
  grid-template-columns: 126px 1fr 258px;
  gap: 25px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    gap: 10px;
  }
}

.rowText {
  color: var(--dark-color);
}

.codeSelect {
  margin-top: -5px;
}


.btnClose {
  padding: 4px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #d9d9d9;
}

.closeIcon {
  width: 24px;
  height: 24px;
  mask-image: url("../../../1_shared/assets/pictures/close.svg");
  background-color: var(--primary-btn-color);
}