@import 'app/styles/breakpoints';

.root {
  position: relative;
  overflow: hidden;
  display: flex;
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px !important;

  @media screen and (max-width: map-get($breakpoints, xl)) {
    max-height: 300px;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    align-self: center;
    max-width: 50%;
    max-height: 50%;
  }
}

.noDataLogo {
  width: auto;
}

.antImage {
  aspect-ratio: 9 / 16;
  object-fit: cover;
}

.darkRoot {
  min-height: 250px;
}
