@import 'app/styles/global/mixins';
@import 'app/styles/global/variables';

.root {
  padding: 20px 120px;
  position: relative;
  overscroll-behavior: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  height: 100%;

  @include tablet {
    padding: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    align-items: start;
  }
}

.paginationBlock {
  display: flex;
  align-items: center;
  gap: 10px;

  .btn {
    padding: 5px !important;
    aspect-ratio: 1/1;

    &:hover {
      svg {
        path {
          stroke: var(--white-color);
        }
      }
    }
  }
}

.loaderWrapper {
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.arrowNext {
  transform: rotate(180deg);
}

.calandar {
  width: calc(100vw - 240px);
  height: 100% !important;
  overflow: auto;

  @include tablet {
    width: calc(100vw - 40px);
    height: calc(100vh - 60px) !important;
  }

  :global(.rbc-time-content) {
    position: unset !important;
    overflow-y: unset !important;
    border: none;
  }

  :global(.rbc-events-container) {
    margin-right: 0 !important;
  }

  :global(.rbc-time-view) {
    min-width: 1100px;
  }

  :global(.rbc-time-gutter) {
    div {
      max-width: 50px !important;
      min-width: 50px !important;
    }
  }

  :global(.rbc-time-column) {
    border: none !important;
  }

  :global(.rbc-time-header) {
    background-color: white;
    min-width: 1100px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 13;
  }

  :global(.rbc-time-gutter) {
    background-color: white;
    z-index: 12;
    position: sticky;
    left: 0;
  }

  :global(.rbc-time-header-content) {
    border-bottom: 1px solid #e9e9e9;
  }

  :global(.rbc-time-view) {
    border: none !important;
  }

  :global(.rbc-button-link) {
    text-align: left;
  }

  :global(.rbc-timeslot-group) {
    border-color: #e9e9e9;
  }

  :global(.rbc-day-slot .rbc-time-slot) {
    border-top: none;
  }

  :global(.rbc-time-content > * + * > *) {
    border-color: #e9e9e9 !important;
  }

  :global(.rbc-today) {
    border-bottom: none !important;
    background-color: transparent;
  }

  :global(.rbc-current-time-indicator) {
    display: flex;
    align-items: center;
    background-color: var(--primary-btn-color) !important;

    &:after {
      position: absolute;
      content: '';
      left: -5.5px;
      display: flex;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background-color: var(--primary-btn-color);
    }
  }

  :global(.rbc-header) {
    border-bottom: none !important;
  }

  :global(.rbc-allday-cell) {
    display: none;
  }
}
