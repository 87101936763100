@import './breakpoints.scss';
@import 'variables';
@import './global/btn';

@font-face {
  font-family: 'Inter';
  src: url('../../1_shared/assets/fonts/Inter-VariableFont_slnt,wght.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('../../1_shared/assets/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../1_shared/assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../1_shared/assets/fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: '21 Cent Italic';
  font-style: italic;
  src: url('../../1_shared/assets/fonts/21Cent-Italic.ttf') format('truetype');
}

  h1 {
    font-family: var(--title-font-family), sans-serif;
  }

  h2 {
    font-family: var(--title-font-family), sans-serif;
  }

  h3 {
    font-family: var(--title-font-family), sans-serif;
  }

  h4 {
    font-family: var(--title-font-family), sans-serif;
  }

  h5 {
    font-family: var(--title-font-family), sans-serif;
  }

  h6 {
    font-family: var(--title-font-family), sans-serif;
  }


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
