$primary-btn-color: #067647;
$primary-btn-hover-color: #055d39;
$mint-color: #f3faff;
$gray-color: #a1a1a1;
$primary-slot-superVision-color: #05a662;
$primary-slot-popup: #bcf8d8;
$light-bg: #E6F1ED;
$light-chip: #E6F1ED;
$card-dark: #055d39;
$icon-bg-spec: #067647;;
$icon-text-spec: #069857;
$icon-arrow-spec: #067647;;
$bg-gradient: radial-gradient(21.12% 83.14% at 72.43% 44.71%, rgba(6, 118, 71, 0.2) 0%, rgba(6, 118, 71, 0) 100%), linear-gradient(0deg, #F3FAFF, #F3FAFF), linear-gradient(0deg, #F8F8F8, #F8F8F8);


$secondary-btn-color: #bcf8d8;
$secondary-btn-text-color: #069857;
$secondary-btn-hover-color: #055d39;
$secondary-btn-text-hover-color: #fff;
$secondary-btn-disabled-color: #797979;
$footer-bg:#E6F1ED;
$border-footer: 1px solid #{$primary-btn-color};
$footer-text-color:#069857;
$footer-input: #067647;

$title-font-family: Inter;
$m-font-family: Inter;


:root {
  --primary-btn-color: #{$primary-btn-color};
  --primary-btn-hover-color: #{$primary-btn-hover-color};
  --primary-slot-superVision-color: #{$primary-slot-superVision-color};
  --primary-slot-popup: #{$primary-slot-popup};
  --border-footer: #{$border-footer};
  --secondary-btn-color: #{$secondary-btn-color};
  --secondary-btn-text-color: #{$secondary-btn-text-color};
  --secondary-btn-hover-color: #{$secondary-btn-hover-color};
  --secondary-btn-text-hover-color: #{$secondary-btn-text-hover-color};
  --secondary-btn-disabled-color: #{$secondary-btn-disabled-color};
  --title-font-family: #{$title-font-family};
  --m-font-family: #{$m-font-family};
  --mint-color: #{$mint-color};
  --gray-color: #{$gray-color};
  --icon-bg-spec: #{$icon-bg-spec};
  --icon-text-spec: #{$icon-text-spec};
  --icon-arrow-spec: #{$icon-arrow-spec};
  --footer-bg:#{$footer-bg};
  --footer-text-color:#{$footer-text-color};
  --footer-input:#{$footer-bg};
  --bg-gradient: #{$bg-gradient};
  --white-color: #fff;
  --dark-color: #000;
  --light-blue: #{$light-bg};
  --light-chip: #{$light-chip};
  --card-dark: #{$card-dark};
}