@import 'app/styles/breakpoints';

.loading {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerText {
  padding: 20px 0;
}

.iconLoad {
  font-size: 36px !important;
}

.viewMore {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;

  button {
    padding: 20px;
  }
}

.content {
  margin: 0 20px;
}

.block {
  &:before {
    position: absolute;
    content: '';
    top: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    color: #e8e8e8;
  }
}

.catalogWrapper {
  display: grid;
  grid-template-columns: 20% 1fr;
  gap: 30px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: flex;
    flex-direction: column;
  }
}


.filters {
  position: relative;
  width: 100%;
  margin-right: 10px;
}
