
.root {
  position: relative;
  overflow-x: auto;
}

.tableCont {
  display: table;
  width: 100%;
  min-width: 1050px;
}

.columnsTitle {
  position: relative;

  th {
    padding: 10px 0;
  }

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #E8E8E8;
  }
}


.item {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color:#E8E8E8;
  }
}

.subItem {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.longText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btnText {
  display: flex;
  min-width: 165px;
  align-items: center;
  background: transparent;
  gap: 5px;
  border: none;
  border-radius: 12px;
  .calendar {
    width: 24px;
    height: 24px;
    mask-image: url("../../../1_shared/assets/pictures/stack/icons/calendar.svg");
    background-color: var(--gray-color);
    background-repeat: no-repeat;
  }

  &:hover {
    .btnTextDescr {
      color: var(--primary-btn-color);
    }
    .calendar {
      background-color: var(--primary-btn-color);
    }
  }
}

.btnDanger {
  background: #FFE3E1 !important;
  color: #CC3B33 !important;
}

