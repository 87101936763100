@import "app/styles/global/mixins";
@import "app/styles/global/variables";

.to-read {
  padding: 50px 0;

  @include mobile {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

.to-read_arrow {
  width: 24px;
  height: 24px;
  mask-image: url("../../../assets/pictures/stack/stack.svg#arrow-right") ;
  background-color: var(--primary-btn-color);
}


.to-read__container {
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.to-read__heading {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;

  @include tablet {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}

.to-read__title {
  margin: 0;

  @include tablet {
    margin-bottom: 20px;
  }

  @include mobile {
    margin-bottom: 10px;
  }
}

.to-read__subtitle {
  color: $color-dot-grey;
  padding-left: 20px;
  padding-right: 50px;
  margin: 0;

  @include tablet {
    max-width: 400px;
    padding-left: 0;
  }
}

.to-read__list-wrapper {
  @include tablet {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  @include mobile {
    padding-bottom: 10px;
  }
}

.to-read__list {
  @include list();

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @include tablet {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
  }
}

.to-read__item {
  @include tablet {
    width: 210px;
  }

  @include mobile {
    width: 168px;
  }
}

.to-read__img {
  display: inline-block;
  border-radius: 20px;
  margin-bottom: 10px;

  @include mobile {
    width: 130px;
    height: 125px;
    border-radius: 10px;
  }
}

.to-read__type {
  color: $color-dot-grey;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include mobile {
    font-size: 8px;
  }
}

.to-read__item-title {
  font: inherit;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 23px;
}

.to-read__link {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-bottom: 24px;

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}














